import { defineAsyncComponent } from 'vue'

export function getLeftMenu() {
  const TABS = [
    {
      index: 1,
      key: '/game/game-hot',
      title: 'Hot',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-fire.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-fire-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue'))
    },
    {
      index: 2,
      key: '/the-thao',
      title: 'Thể thao',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-ball.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-ball-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/the-thao/index.vue'))
    },
    {
      index: 3,
      key: '/livecasino/all',
      active: 'livecasino',
      title: 'Casino',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-casino.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-casino-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/livecasino/index.vue'))
    },
    {
      index: 4,
      key: '/game/table-game',
      title: 'Table games',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-table-game.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-table-game-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue'))
    },
    {
      index: 5,
      key: '/game/quay-so',
      title: 'Quay Số',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-quay-so.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-quay-so-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue'))
    },
    {
      index: 6,
      key: '/game/game-bai',
      title: 'game bài',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-game-bai.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-game-bai-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue'))
    },
    {
      index: 7,
      key: '/game/no-hu',
      title: 'Nổ hũ',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-nohu.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-nohu-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue'))
    },
    {
      index: 8,
      key: '/game/lo-de',
      title: 'Lô đề',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-lode.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-lode-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue'))
    },
    {
      index: 9,
      key: '/game/ban-ca',
      title: 'bắn cá',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-banca.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-banca-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue'))
    },
    {
      index: 10,
      key: '/game/game-nhanh',
      title: 'game nhanh',
      iconImage: '/assets/images/components/mobile/pages/home/game-tabs/icon-quickgame.png',
      iconImageDefault: '/assets/images/components/mobile/pages/home/game-tabs/icon-quickgame-default.png',
      contentTab: defineAsyncComponent(() => import('~/components/mobile/pages/games/index.vue'))
    }
  ]

  return { TABS }
}
